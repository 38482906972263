<template>
  <div class="mcr-header">
    <img :src="previewSrc" :data-src="src" class="lazyload" :style="imageStyles" />
    <div class="cover-gradient"></div>
    <h2 class="header-title">
      <span class="strap-line">{{ strapline }}</span>
      <div class="title" v-html="formatTitle"></div>
    </h2>
  </div>
</template>

<script>
import {getImageLazyLoadPlaceholderSrc, getImageSrc} from '@common/utils/utils';

export default {
  props: {
    title: String,
    image: String,
    strapline: String,
    imagePosition: String,
  },
  computed: {
    src() {
      return getImageSrc(this.image);
    },
    previewSrc() {
      return getImageLazyLoadPlaceholderSrc(this.image);
    },
    formatTitle() {
      if (!this.title) {
        return;
      }
      return this.title.replace(/{/g, '<').replace(/}/g, '>');
    },
    imageStyles() {
      return {'object-position': this.imagePosition};
    },
  },
};
</script>
<style scoped lang="scss">
.mcr-header {
  height: 50vh;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid #282828;
  margin-bottom: 60px;
  min-height: 532px;

  .strap-line {
    font-size: 0.7em;
    opacity: 0.6;
  }

  .header-title {
    position: absolute;
    left: 0;
    bottom: 50px;
    color: #fff;
    text-shadow: 2px 2px 2px rgb(0 0 0 / 80%);
    text-align: center;
    margin-bottom: 0;
    width: 100%;

    @media only screen and (min-width: $breakpoint-phablet) {
      width: calc(100% - var(--report-closed-menu-width));
      left: var(--report-closed-menu-width);
    }

    .title {
      padding: 0 15px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .cover-gradient {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0;
    top: 50%;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    height: 40vh;
    min-height: 300px;
    margin-bottom: 12px;
    .header-title {
      bottom: 24px;
    }
  }
}
</style>
